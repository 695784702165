import {
  Box,
  useToast,
  ChakraProps,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { CheckCircle } from '@phosphor-icons/react';
import { ReactNode, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import {
  Form,
  FormRenderProps,
  FormProps as FinalFormProps,
} from 'react-final-form';
import { useRouter } from 'next/router';
import { useBlog, useTranslation } from '../../lib/hooks.context';
import { Form as FormProps } from '../../types';
import Heading from '../Heading';
import { WP_Form_Response } from '../../pages/api/form';

export { default as FormField } from './FormFields';

interface Props extends Pick<FormProps, 'id' | 'type'> {
  children: (formProps: FormRenderProps, emailSent: boolean) => ReactNode;
  validate?: FinalFormProps['validate'];
  noThankYou?: boolean;
  initialValues?: Record<string, any>;
}

const formStyles: SystemStyleObject = {
  a: { textDecoration: 'underline' },
};

const ThankYou = ({
  emailSent,
  type,
}: { emailSent?: boolean } & Pick<FormProps, 'type'>) => {
  const t = useTranslation();

  return (
    <Box
      data-test-id={`form-thankyou-${type}`}
      m="0 auto"
      maxWidth="600px"
      textAlign="center"
    >
      <Text color="highlightColor" sx={{ svg: { m: '0 auto 1em' } }}>
        <CheckCircle size="60" />
      </Text>
      <Heading variant="h3" as="p" whiteSpace="break-spaces" mb="4">
        {t(`${type}_form_submit_thankyou`, {
          fallback: 'form_submit_thankyou',
        })}
      </Heading>
      {emailSent && type !== 'address' ? (
        <Text>
          {t(`${type}_form_submit_thankyou_email_notification`, {
            fallback: 'form_submit_thankyou_email_notification',
          })}
        </Text>
      ) : null}
    </Box>
  );
};

const MhyForm = ({
  children,
  id,
  type,
  validate,
  noThankYou,
  initialValues,
  ...rest
}: Props & ChakraProps) => {
  const blogInfo = useBlog(true);
  const { locale: lang } = useRouter();
  const { name: site, blog_id } = blogInfo;
  const [mailSent, setState] = useState(false);
  const toast = useToast();
  const t = useTranslation();

  const onSubmit = async (values) => {
    /* submit values by id, blog and type */
    try {
      const res = await fetch('/api/form', {
        method: 'POST',
        body: JSON.stringify({
          lang,
          blog_id,
          site,
          id,
          type,
          values,
        }),
      });

      const data = (await res.json()) as WP_Form_Response;

      if (data && data[FORM_ERROR]) {
        const errMsg: SubmissionErrors | string = data[FORM_ERROR];
        toast({
          title: typeof errMsg === 'object' ? Object.values(errMsg)[0] : errMsg,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return errMsg;
      }
      if (
        data &&
        typeof data?.message === 'string' &&
        data?.informedParties?.backOffice
      ) {
        setState(true);
        toast({
          title: data?.message,
          status: 'success',
          duration: 7500,
          isClosable: true,
        });
      } else {
        throw new Error('Form was not received by back office');
      }
    } catch (error) {
      console.error('Submit failed', error);
      Sentry.captureException(error, { extra: { formType: type } });
      const errMsg = t('form_submit_failed_unknown');

      toast({
        title: errMsg,
        status: 'error',
        duration: 15000,
        isClosable: true,
      });
      return errMsg;
    }
    return null;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={(props) => (
        <Box as="form" onSubmit={props.handleSubmit} {...rest} sx={formStyles}>
          {!noThankYou && props.submitSucceeded ? (
            <ThankYou emailSent={mailSent} type={type} />
          ) : (
            children(props, mailSent)
          )}
          {props.submitErrors ? (
            <Text textStyle="error" pt="3">
              {typeof props.submitErrors === 'string'
                ? props.submitErrors
                : t('form_has_errors')}
            </Text>
          ) : null}
        </Box>
      )}
    />
  );
};

export default MhyForm;
