import { Box } from '@chakra-ui/react';
import { ContactBlock as Props } from '../../types';
import ContactForm from '../Forms/ContactForm';
import Heading from '../Heading';

const ContactBlock = ({ title, form }: Props) =>
  form ? (
    <Box
      data-test-id="section-contact-block"
      as="section"
      py={8}
      px={[8, 8, 8, '8.33333333%']}
      borderRadius="lg"
      bg="greenLight"
    >
      <ContactForm {...form} mx="auto" maxW="1100px">
        <Heading mb="8">{title}</Heading>
      </ContactForm>
    </Box>
  ) : null;

export default ContactBlock;
