import { FormField } from '../types';
import { isEmail } from './utils';

export const isPhoneNumber = (str: string): boolean => {
  // Lax method and mirrors API
  const value = str.replace(/\s|-/g, '');
  if (value.startsWith('+')) {
    return value.length <= 13 && value.length >= 12;
  }
  return value.length > 6 && value.length <= 10;
};

export const validateFieldByType = ({
  required,
  type,
  value = '',
}: Pick<FormField, 'type' | 'required'> & { value?: string }):
  | undefined
  | string => {
  switch (type) {
    case 'email': {
      if (!required && !value) {
        return undefined;
      }

      return isEmail(value || '') ? undefined : 'Sähköposti ei ole oikea';
    }
    case 'phone': {
      if (!required && !value) {
        return undefined;
      }

      return isPhoneNumber(value || '')
        ? undefined
        : 'Puhelinnumero ei ole oikea';
    }

    case 'checkbox': {
      return required && (!value || value?.length < 1)
        ? 'Pakollinen kenttä'
        : undefined;
    }

    default:
      return required && (!value || value?.length < 2)
        ? 'Pakollinen kenttä'
        : undefined;
  }
};

export default null;
