import { FC } from 'react';
import { useRouter } from 'next/router';
import { Box, Button, ChakraProps, Flex } from '@chakra-ui/react';
import { ValidationErrors } from 'final-form';
import { Form as FormProps } from '../../types';
// import { useAppdata } from '../../lib/hooks.context';
import Form, { FormField } from '../Form';
import { validateFieldByType } from '../../lib/utils.validate';
import Link from '../Link';
import { useTranslation } from '../../lib/hooks.context';

// NOTE: comented code that works fine, but not used for now. Might be used later to create autocomplete field with options
//
// const tagsToOptions = (tags: string[], valuePrefix = '') =>
//   tags.map(
//     (label): FormFieldOption => ({
//       value: valuePrefix ? `${valuePrefix}${label}` : label,
//       label,
//     }),
//   );

// const resolveContactFields = ({
//   fields,
//   sites,
//   site,
// }: Pick<FormProps, 'fields'> & { sites: Site[]; site: string }) =>
//   fields.reduce((acc, field) => {
//     if (field.type.startsWith('resolve:')) {
//       // This is a type that needs resolving in frontend. Only resolve types in FE if there's a major performance gain from it.
//       switch (field.type.replace('resolve:', '')) {
//         case 'territory': {
//           // TODO: swap field types to autocomplete and add support for it
//           if (site) {
//             const territories = sites.find(({ name }) => name === site)?.tags;
//             const resolvedField = {
//               ...field,
//               type: 'select',
//               options: tagsToOptions(territories),
//             };

//             return [...acc, resolvedField];
//           }
//           const resolvedField = {
//             ...field,
//             type: 'select',
//             options: sites.map(({ name, blogname, tags }) => ({
//               label: blogname,
//               options: tagsToOptions(tags, `${name}|`),
//             })),
//           };

//           return [...acc, resolvedField];
//         }
//         default:
//           console.warn(`Unknown type to resolve - ${field.type}`);
//           return acc;
//       }
//     }
//     return [...acc, field];
//   }, []);

const ContactForm: FC<FormProps & ChakraProps> = ({
  fields,
  children,
  legal,
  ...rest
}) => {
  const t = useTranslation();
  const { asPath } = useRouter();

  // const { sites, subsite: site } = useAppdata();

  // const resolvedFields = resolveContactFields({ fields, sites, site });

  const onValidate = (values = {}) => {
    const errors: ValidationErrors = fields.reduce(
      (acc, { required, name, type }) => ({
        ...acc,
        [name]: validateFieldByType({ required, type, value: values[name] }),
      }),
      {},
    );

    return errors;
  };

  return (
    <Form
      py="8"
      validate={onValidate}
      initialValues={{ lahde: `https://www.mhy.fi${asPath}` }}
      {...rest}
    >
      {({ submitting }) => (
        <>
          {children}
          <Box
            sx={{ '&': { columnCount: 2, columnWidth: '280px' } }}
            mb="8"
            data-test-id="contact-form-fields"
          >
            {fields.map((field) => (
              <FormField key={field.name} {...field} />
            ))}
          </Box>
          <Box pt="2">
            <Button
              type="submit"
              variant="solidGreen"
              disabled={submitting}
              isLoading={submitting}
              loadingText={t('button_submitting')}
              size="large"
            >
              {t('button_submit')}
            </Button>
          </Box>
          {legal ? (
            <Flex pt="4">
              <Link href={legal.url}>{legal.title}</Link>
            </Flex>
          ) : null}
        </>
      )}
    </Form>
  );
};

export default ContactForm;
