import { FormControl, FormLabel } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { isSpecialFormField } from '../../lib/utils.forms';
import { FormFieldTypes } from '../../types';

const SelectForestAssociationField = dynamic(
  () => import('./SelectForestAssociationField'),
);
const ContactMethodField = dynamic(() => import('./ContactMethodField'));
const OtherEventParticipantsField = dynamic(
  () => import('./OtherEventParticipantsField'),
);
const FormField = dynamic(() => import('./FormField'));

const FieldOrFields = (formField: FormFieldTypes) => {
  const { type, fields, ...props } = formField;
  if (type === 'fieldset') {
    if (!fields || !fields?.length) {
      return null;
    }
    return (
      <FormControl mb="6" as="fieldset">
        <FormLabel as="legend" fontSize="lg" fontWeight="normal">
          {props.label}
        </FormLabel>
        {(
          fields as (Omit<FormFieldTypes, 'type'> & {
            type: Exclude<FormFieldTypes['type'], 'fieldset'>;
          })[]
        )?.map(({ type: fieldType, ...field }) => {
          switch (fieldType) {
            case 'resolve:site':
              return (
                <SelectForestAssociationField {...field} key={field.name} />
              );
            default:
              return <FormField {...field} type={fieldType} key={field.name} />;
          }
        })}
      </FormControl>
    );
  }

  if (!isSpecialFormField(formField)) {
    return <FormField type={type} {...props} />;
  }

  switch (type) {
    case 'resolve:site':
      return (
        <SelectForestAssociationField
          {...props}
          {...(fields ? { fields } : null)}
        />
      );
    case 'resolve:contact-method':
      return <ContactMethodField {...formField} />;
    case 'resolve:other_participants':
      return <OtherEventParticipantsField {...formField} />;
    default:
      return <FormField type={type} {...props} />;
  }
};

export default FieldOrFields;
